<template>
  <pull-refresh
    v-model="dataIsLoading"
    @refresh="() => $_ajax()">
    <div class="activity__wrapper">
      <v-select
        v-model="dataSelectLastDayValue"
        :options="dataSelectOptionsLastDay"
        :filterable="false"
        :searchable="false"
        :components="{Deselect: dataComponentDeselect}"
        placeholder="Last Days"
        :class="['call-select', dataSelectLastDayValue ? 'call-select--value' : null]"
        @input="$_ajax">
        <template #open-indicator="{ attributes }">
          <span v-bind="attributes">
            <font-awesome-icon :icon="dataIconDown" />
          </span>
        </template>
      </v-select>
      <div class="activity__block">
        <div class="activity-item">
          <div class="activity-item__head">
            <span class="green">
              <font-awesome-icon
                :icon="dataIconPhone" />
            </span>
            Finished
          </div>
          <div class="activity-item__body">
            {{ dataReportStat.outcoming_finished_calls_count || 0 }}
          </div>
          <div class="activity-item__footer">
            <span :class="dataReportStat.outcoming_finished_calls_count_difference >= 0 ? 'success' : 'danger'">{{ (dataReportStat.outcoming_finished_calls_count_difference > 0 ? '+' : '') }}{{ dataReportStat.outcoming_finished_calls_count_difference || 0 }}</span>
            <p>|</p>
            <span :class="dataReportStat.outcoming_finished_calls_count_difference >= 0 ? 'success' : 'danger'"><font-awesome-icon :icon="(dataReportStat.outcoming_finished_calls_count_difference_percents >= 0 ? dataIconArrowUp : dataIconArrowDown)" />
              {{ templatePositiveNumber(dataReportStat.outcoming_finished_calls_count_difference_percents) || 0 }}%
            </span>
          </div>
        </div>

        <div class="activity-item">
          <div class="activity-item__head">
            <span class="red">
              <font-awesome-icon
                :icon="dataIconPhone" />
            </span>
            Cancelled
          </div>
          <div class="activity-item__body">
            {{ dataReportStat.outcoming_cancelled_calls_count || 0 }}
          </div>
          <div class="activity-item__footer">
            <span :class="dataReportStat.outcoming_cancelled_calls_count_difference >= 0 ? 'success' : 'danger'">{{ dataReportStat.outcoming_cancelled_calls_count_difference > 0 ? '+' : '' }}{{ dataReportStat.outcoming_cancelled_calls_count_difference || 0 }}</span>
            <p>|</p>
            <span :class="dataReportStat.outcoming_cancelled_calls_count_difference >= 0 ? 'success' : 'danger'"><font-awesome-icon :icon="(dataReportStat.outcoming_cancelled_calls_count_difference_percents >= 0 ? dataIconArrowUp : dataIconArrowDown)" />
              {{ templatePositiveNumber(dataReportStat.outcoming_cancelled_calls_count_difference_percents) || 0 }}%
            </span>
          </div>
        </div>

        <div class="activity-item">
          <div class="activity-item__head">
            <span><img src="/img/incoming-outline.svg"></span>
            &nbsp;Incoming
          </div>
          <div class="activity-item__body">
            {{ dataReportStat.incoming_calls_count || 0 }}
          </div>
          <div class="activity-item__footer">
            <span :class="dataReportStat.incoming_calls_count_difference >= 0 ? 'success' : 'danger'">{{ dataReportStat.incoming_calls_count_difference > 0 ? '+' : '' }}{{ dataReportStat.incoming_calls_count_difference || 0 }}</span>
            <p>|</p>
            <span :class="dataReportStat.incoming_calls_count_difference >= 0 ? 'success' : 'danger'"><font-awesome-icon :icon="(dataReportStat.incoming_calls_count_difference_percents >= 0 ? dataIconArrowUp : dataIconArrowDown)" />
              {{ templatePositiveNumber(dataReportStat.incoming_calls_count_difference_percents) || 0 }}%
            </span>
          </div>
        </div>
      </div>
      <div class="activity__block">
        <div class="activity-item activity-item--mobile">
          <div class="activity-item__head">
            <span>
              <font-awesome-icon
                :icon="dataIconPhone" />
            </span>
            Spoken
          </div>
          <div class="activity-item--mobile-content">
            <div class="activity-item__body">
              {{ dataReportStat.calls_duration | dateReport }}
            </div>
            <div class="activity-item__footer">
              <span :class="dataReportStat.calls_duration_difference_percents >= 0 ? 'success' : 'danger'">{{ dataReportStat.calls_duration_difference | dateReport }}</span>
              <p>|</p>
              <span :class="dataReportStat.calls_duration_difference_percents >= 0 ? 'success' : 'danger'"><font-awesome-icon :icon="(dataReportStat.calls_duration_difference_percents >= 0 ? dataIconArrowUp : dataIconArrowDown)" />
                {{ templatePositiveNumber(dataReportStat.calls_duration_difference_percents) || 0 }}%
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="activity__block">
        <div class="activity-item activity-item--mobile">
          <div class="activity-item__head">
            <span class="success">
              <font-awesome-icon
                :icon="dataIconSms" />
            </span>
            SMS
          </div>
          <div class="activity-item--mobile-content">
            <div class="activity-item__body">
              {{ dataReportStat.sms_count || 0 }}
            </div>
            <div class="activity-item__footer">
              <span :class="(dataReportStat.sms_count_difference_percents >= 0) ? 'success' : 'danger'">{{ dataReportStat.sms_count_difference > 0 ? '+' : '' }}{{ dataReportStat.sms_count_difference || 0 }}</span>
              <p>|</p>
              <span :class="dataReportStat.sms_count_difference_percents >= 0 ? 'success' : 'danger'"><font-awesome-icon :icon="(dataReportStat.sms_count_difference_percents >= 0 ? dataIconArrowUp : dataIconArrowDown)" />
                {{ templatePositiveNumber(dataReportStat.sms_count_difference_percents) || 0 }}%
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="activity__block">
        <div class="activity-item activity-item--mobile">
          <div class="activity-item__head">
            <span class="success">
              <font-awesome-icon
                :icon="dataIconGift" />
            </span>
            Gifts
          </div>
          <div class="activity-item--mobile-content">
            <div class="activity-item__body">
              {{ dataReportStat.bonuses_count || 0 }}
            </div>
            <div class="activity-item__footer">
              <span :class="(dataReportStat.bonuses_count_difference_percents >= 0) ? 'success' : 'danger'">{{ dataReportStat.bonuses_count_difference > 0 ? '+' : '' }}{{ dataReportStat.bonuses_count_difference || 0 }}</span>
              <p>|</p>
              <span :class="dataReportStat.bonuses_count_difference_percents >= 0 ? 'success' : 'danger'"><font-awesome-icon :icon="(dataReportStat.bonuses_count_difference_percents >= 0 ? dataIconArrowUp : dataIconArrowDown)" />
                {{ templatePositiveNumber(dataReportStat.bonuses_count_difference_percents) || 0 }}%
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="activity__block">
        <div class="activity-item activity-item--mobile">
          <div class="activity-item__head">
            <span class="success">
              <font-awesome-icon
                :icon="dataIconTask" />
            </span>
            Closed Assigned
          </div>
          <div class="activity-item--mobile-content">
            <div class="activity-item__body">
              {{ dataReportStat.auto_closed_tasks_count || 0 }}
            </div>
            <div class="activity-item__footer">
              <span :class="(dataReportStat.auto_closed_tasks_count_difference >= 0) ? 'success' : 'danger'">{{ dataReportStat.auto_closed_tasks_count_difference > 0 ? '+' : '' }}{{ dataReportStat.auto_closed_tasks_count_difference || 0 }}</span>
              <p>|</p>
              <span :class="dataReportStat.auto_closed_tasks_count_difference_percents >= 0 ? 'success' : 'danger'"><font-awesome-icon :icon="(dataReportStat.auto_closed_tasks_count_difference_percents >= 0 ? dataIconArrowUp : dataIconArrowDown)" />
                {{ templatePositiveNumber(dataReportStat.auto_closed_tasks_count_difference_percents) || 0 }}%
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="activity__block">
        <div class="activity-item activity-item--mobile">
          <div class="activity-item__head">
            <span class="success">
              <font-awesome-icon
                :icon="dataIconGaugeMax" />
            </span>
            KPI
          </div>
          <div class="activity-item--mobile-content">
            <div class="activity-item__body">
              {{ dataReportStat.kpi || 0 }}
            </div>
            <div class="activity-item__footer">
              <span :class="(dataReportStat.kpi_difference >= 0) ? 'success' : 'danger'">{{ dataReportStat.kpi_difference > 0 ? '+' : '' }}{{ dataReportStat.kpi_difference || 0 }}</span>
              <p>|</p>
              <span :class="dataReportStat.kpi_difference_percents >= 0 ? 'success' : 'danger'"><font-awesome-icon :icon="(dataReportStat.kpi_difference_percents >= 0 ? dataIconArrowUp : dataIconArrowDown)" />
                {{ templatePositiveNumber(dataReportStat.kpi_difference_percents) || 0 }}%
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="activity__block">
        <div class="activity-item activity-item--mobile">
          <div class="activity-item__head">
            <span class="success">
              <font-awesome-icon
                :icon="dataIconUser" />
            </span>
            VIP
          </div>
          <div class="activity-item--mobile-content">
            <div class="activity-item__body">
              {{ dataReportStat.auto_closed_vip_tasks_count || 0 }}
            </div>
            <div class="activity-item__footer">
              <span :class="(dataReportStat.auto_closed_vip_tasks_count_difference >= 0) ? 'success' : 'danger'">{{ dataReportStat.auto_closed_vip_tasks_count_difference > 0 ? '+' : '' }}{{ dataReportStat.auto_closed_vip_tasks_count_difference || 0 }}</span>
              <p>|</p>
              <span :class="dataReportStat.auto_closed_vip_tasks_count_difference_percents >= 0 ? 'success' : 'danger'"><font-awesome-icon :icon="(dataReportStat.auto_closed_vip_tasks_count_difference_percents >= 0 ? dataIconArrowUp : dataIconArrowDown)" />
                {{ templatePositiveNumber(dataReportStat.auto_closed_vip_tasks_count_difference_percents) || 0 }}%
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="activity__block">
        <div class="activity-item activity-item--mobile">
          <div class="activity-item__head">
            <span class="danger">
              <font-awesome-icon
                :icon="dataIconTask" />
            </span>
            Manually Closed
          </div>
          <div class="activity-item--mobile-content">
            <div class="activity-item__body">
              {{ dataReportStat.manually_closed_tasks_count || 0 }}
            </div>
            <div class="activity-item__footer">
              <span :class="(dataReportStat.manually_closed_tasks_count_difference >= 0) ? 'success' : 'danger'">{{ dataReportStat.manually_closed_tasks_count_difference > 0 ? '+' : '' }}{{ dataReportStat.manually_closed_tasks_count_difference || 0 }}</span>
              <p>|</p>
              <span :class="dataReportStat.manually_closed_tasks_count_difference_percents >= 0 ? 'success' : 'danger'"><font-awesome-icon :icon="(dataReportStat.manually_closed_tasks_count_difference_percents >= 0 ? dataIconArrowUp : dataIconArrowDown)" />
                {{ templatePositiveNumber(dataReportStat.manually_closed_tasks_count_difference_percents) || 0 }}%
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </pull-refresh>
</template>
<script>
import {
  faArrowDown,
  faArrowUp,
  faCheck,
  faCheckCircle,
  faUser,
  faClock,
  faComment,
  faGift,
  faPhone, faSms, faGaugeHigh
} from '@fortawesome/free-solid-svg-icons';
import { REPORTS_GET_STATS_OPERATOR } from '../../../store/types/actions.types';
import { mapGetters } from 'vuex';
import helper from '../../../service/helper';
import { PullRefresh, Toast } from 'vant';
import selectReportPeriod from '../../../mixins/select/selectReportPeriod';

export default {
  name: 'ReportMobilePage',
  components: { PullRefresh },
  mixins: [selectReportPeriod],
  data () {
    return {
      dataIconPhone: faPhone,
      dataIconClock: faClock,
      dataIconComment: faComment,
      dataIconGift: faGift,
      dataIconCheck: faCheck,
      dataIconTask: faCheckCircle,
      dataIconUser: faUser,
      dataIconArrowUp: faArrowUp,
      dataIconArrowDown: faArrowDown,
      dataIconGaugeMax: faGaugeHigh,
      dataIconSms: faSms,
      dataIsLoading: false,
      dataReportStat: {}
    };
  },
  computed: {
    ...mapGetters({
      computedStoreCurrentUser: 'auth/currentUser'
    })
  },
  deactivated () {
    this.$destroy();
  },
  created () {
    if (helper.isEmpty(this.computedStoreCurrentUser)) {
      const _watchMe = this.$watch('computedStoreCurrentUser', async _ => {
        this.$_ajax();
        _watchMe();
      });
    } else {
      this.$_ajax();
    }
  },
  methods: {
    async $_ajax () {
      if (!this.dataSelectLastDayValue) {
        this.dataSelectLastDayValue = {
          ...this.dataSelectLastDayValueDefault
        };
      }
      const { payload: { stat = {} } } = await this.$store.dispatch(`report/${REPORTS_GET_STATS_OPERATOR}`, {
        teamId: helper.getOneArray(this.computedStoreCurrentUser.teams)?.id,
        operatorId: this.computedStoreCurrentUser.id,
        params: {
          period: this.dataSelectLastDayValue?.key
        }
      });
      this.dataReportStat = stat;
      this.$forceUpdate();
      if (this.dataIsLoading) {
        Toast('Refresh Success');
        this.dataIsLoading = false;
      }
    },
    templatePositiveNumber (value) {
      if (+value < 0) {
        return value * -1;
      }
      return value;
    }
  }
};
</script>
